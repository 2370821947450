.login_container {
  width: 100%;
  top: calc(50% - 13rem);
  height: 26rem;
  background-color: transparent !important;
  text-align: center;
  border: none !important;
}

.login_form_forgot {
  float: center !important;
}

.login_form_button {
  width: 100% ;
  margin-bottom: 10px ;
}

