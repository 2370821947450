.map {
  position: absolute;
}

.customControl {
  right: 1rem;
  bottom: 2rem;
}

.configCard {
  position: relative !important;
  z-index: 10;
  float: right;
  right: 2em;
  height: 20rem;
  bottom: 24rem;
  width: 15rem;
}

.configCardAnimation-enter {
  opacity: 0;
}
.configCardAnimation-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.configCardAnimation-exit {
  opacity: 1;
}
.configCardAnimation-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.slider_container {
  width: 80%;
  left: 10%;
  bottom: 2em;
}
