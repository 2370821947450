.logo {
  float: left;
  width: 128px;
}

.header_style {
  padding: 0.2rem 1rem !important;
  border-bottom: 1px solid #dee2e6 !important;
  background-color: #f8f9fa !important;
  line-height: 4em !important;
}

.header_style .ant-row {
  line-height: 4em;
}
.ant-popover-inner-content {
  padding: 5px !important;
}

.ant-popover-placement-bottom {
  top: 3em !important;
}

.button_header {
  padding: 5px;
}

.text_header:hover {
  color: rgb(24, 144, 252) !important;
}

.text_header:focus {
  color: rgb(24, 144, 255) !important;
}
