.legend_container {
  bottom: calc(50% - 160px);
  left: 0em;
  z-index: 9999999999;
  padding: 19px;
  background-color: transparent;
  height: 320px;
  width: 40px;
}

.legend_container:hover {
  bottom: calc(50% - 160px);
  left: 0em;
  z-index: 9999999999;
  padding: 19px;
  background-color: transparent;
  height: 320px;
  width: 40px;
}

.legend {

  text-align: center;
}

.legend .cell {
  height: 28px;
  width: 1.5em;
}

.legend .labels {
  display: flex;
  justify-content: space-between;
}

.legend span {
  font-size: 13px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
}

